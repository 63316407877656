<template>
  <div class="bac-color">
      <vue-header title="业绩提成"/>
      <nav class="pro-nav flex">
          <div class="nav flex-1" :class="{ active: active==0}" @click="()=>this.activeChange(0)">全部</div>
          <div class="nav flex-1" :class="{ active: active==1}" @click="()=>this.activeChange(1)">未结算</div>
          <div class="nav flex-1" :class="{ active: active==2}" @click="()=>this.activeChange(2)">已结算</div>
      </nav>
      <div class="pro-date">
          <div class="pro-start flex">
              <div class="pro-title">起始时间：</div>
              <div class="flex flex-1 flex-y" @click="()=>this.start=true">
                  <div>{{timeSubmit(startDate)}} 00:00</div>
              </div>
          </div>
          <div class="pro-start flex">
              <div class="pro-title">结束时间：</div>
              <div class="flex flex-1 flex-y" @click="()=>this.end=true">
                  <div>{{timeSubmit(endDate)}} 23:59</div>
              </div>
          </div>
      </div>
      <div class="flex flex-x">
          <div class="pro-query" @click="()=>this.detaileListFun({page:1})">查询</div>
      </div>
      <div class="flex flex-x pro-loading" v-if="loading">
          <van-loading />
      </div>
      <div v-if="detaile_list">
            <div class="pro-price flex">
                <div class="pro-total">提成合计</div>
                <div class="pro-color flex-1">{{detaile_list.price}}元</div>
            </div>
            <div class="pro-list" v-for="list in detaile_list.list" v-bind:key="list.id">
                <div class="flex">
                    <div class="pro-name">{{list.use_user.name}}</div>
                    <div class="pro-price-name flex-1">金额<span>{{list.price}}</span>元</div>
                </div>
                <div class="pro-biology flex">
                    <div class="flex-1">{{list.service.service_name}} {{timeSubmit(list.created_at)}}</div>
                    <div v-if="list.status==1" class="red"> 未结算</div>
                    <div v-else >{{timeSubmit(list.jiesuan_time)}} 已结算</div>
                </div>
            </div>
            <div class="picker-bottom" v-on:click.stop v-if="start">
                    <van-datetime-picker
                        v-model="startDate"
                        type="date"
                        title="起始时间"
                        @cancel="()=>this.start=false"
                        @confirm="dateConfirm"
                        visible-item-count="9"
                    />
            </div>
     </div>
     <div class="picker-bottom" v-on:click.stop v-if="end">
            <van-datetime-picker
                v-model="endDate"
                type="date"
                title="截止时间"
                @cancel="()=>this.end=false"
                @confirm="dateConfirm"
                visible-item-count="9"
            />
     </div>
     <div class="picker-curtain" v-if="end||start" @click="()=>{this.end=false;this.start=false;}"></div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions , mapState } from 'vuex';
import { titleFun } from '@/utils';
export default {
  name: 'my',
  components:{
    vueHeader
  },
  data(){ 
    return {
        start:false,
        end:false,
        startDate:new Date(),
        endDate:new Date(),
        active:0
    }
  },
  created:function(){
    this.detaileListFun({
        page:1
    })  
    titleFun('业绩提成');
  },
  computed:{
      ...mapState('my',['detaile_list','loading']),
  },
  methods:{
      activeChange(active){
          this.active = active;
          this.detaileListFun({page:1});
      },
      dateConfirm(){
          this.end=false;
          this.start=false;
      },
      detaileListFun(obj){
          obj.start_date = `${this.timeSubmit(this.startDate)} 00:00`;
          obj.end_date = `${this.timeSubmit(this.endDate)} 23:59`;
          obj.operator_user_id = Number(this.$route.params.id);
          obj.status = this.active;
          obj.size = 300;
          this.detaileListMain(obj)
      },
      timeSubmit(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return `${y}-${m}-${d}`;
      },
      ...mapActions('my',['detaileListMain'])
  }
}
</script>

<style lang="scss" scoped>
  @import './profit';
</style>